import {
  get, post
} from '../utils/http';

// 获取规则
export function getGZPageList(data) {
  return get('fdykh/SY_FDYKH_KHGZ/GetPageList/', data);
}

// 提交规则
export function saveGZ(data) {
  return post('fdykh/SY_FDYKH_KHGZ/Save/', data);
}

// 获取规则
export function getGZInfo(data) {
  return get('fdykh/SY_FDYKH_KHGZ/GetFormData/', data);
}

// 删除规则
export function deleteGZ(data) {
  return get('fdykh/SY_FDYKH_KHGZ/Delete/', data);
}

// 获取批次
export function getPCPageList(data) {
  return get('fdykh/SY_FDYKH_PC/GetPageList/', data);
}

// 提交批次
export function savePC(data) {
  return post('fdykh/SY_FDYKH_PC/Save/', data);
}

// 获取批次
export function getPCInfo(data) {
  return get('fdykh/SY_FDYKH_PC/GetFormData/', data);
}

// 删除批次
export function deletePC(data) {
  return get('fdykh/SY_FDYKH_PC/Delete/', data);
}

// 获取辅导员考核分数
export function getFDYFSPageList(data) {
  return get('fdykh/SY_FDYKH_FS/GetPageList/', data);
}

// 获取学生辅导员评分
export function getSFPF(data) {
  return get('fdykh/SY_FDYKH_XSPF/GetSFPF/', data);
}

// 获取规则
export function getListToPF(data) {
  return get('fdykh/SY_FDYKH_KHGZ/GetListToPF/', data);
}

// 学生提交评分
export function saveXSPF(data) {
  return post('fdykh/SY_FDYKH_XSPF/Save/', data);
}

// 学院提交评分
export function saveXYPF(data) {
  return post('fdykh/SY_FDYKH_XYPF/Save/', data);
}

// 部门提交评分
export function saveBMPF(data) {
  return post('fdykh/SY_FDYKH_BMPF/Save/', data);
}
